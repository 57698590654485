.rrui__tooltip
{
	display     : flex;
	align-items : center;

	padding-left  : calc(0.6rem * 1.5);
	padding-right : calc(0.6rem * 1.5);
	height        : calc(0.6rem * 4);

	color            : var(--rrui-tooltip-text-color);
	background-color : var(--rrui-tooltip-background-color);

	border-radius : var(--rrui-tooltip-border-radius);

	cursor: default;
	outline: none;
}

.rrui__tooltip
{
	opacity: 0;
}

.rrui__tooltip--after-show
{
	opacity: 1;
}

/* `--before-hide` must be placed after `--after-show`. */
.rrui__tooltip--before-hide
{
	opacity: 0;
}

.rrui__tooltip--top
{
	transform: translateY(calc(-1 * var(--rrui-tooltip-hidden-distance)));
}

.rrui__tooltip--top.rrui__tooltip--after-show
{
	transform: translateY(calc(-1 * var(--rrui-tooltip-visible-distance)));
}

/* `--before-hide` must be placed after `--after-show`. */
.rrui__tooltip--top.rrui__tooltip--before-hide
{
	transform: translateY(calc(-1 * var(--rrui-tooltip-hidden-distance)));
}

.rrui__tooltip--left
{
	transform: translateX(calc(-1 * var(--rrui-tooltip-hidden-distance)));
}

.rrui__tooltip--left.rrui__tooltip--after-show
{
	transform: translateX(calc(-1 * var(--rrui-tooltip-visible-distance)));
}

/* `--before-hide` must be placed after `--after-show`. */
.rrui__tooltip--left.rrui__tooltip--before-hide
{
	transform: translateX(calc(-1 * var(--rrui-tooltip-hidden-distance)));
}

.rrui__tooltip--bottom
{
	transform: translateY(var(--rrui-tooltip-hidden-distance));
}

.rrui__tooltip--bottom.rrui__tooltip--after-show
{
	transform: translateY(var(--rrui-tooltip-visible-distance));
}

/* `--before-hide` must be placed after `--after-show`. */
.rrui__tooltip--bottom.rrui__tooltip--before-hide
{
	transform: translateY(var(--rrui-tooltip-hidden-distance));
}

.rrui__tooltip--right
{
	transform: translateX(var(--rrui-tooltip-hidden-distance));
}

.rrui__tooltip--right.rrui__tooltip--after-show
{
	transform: translateX(var(--rrui-tooltip-visible-distance));
}

/* `--before-hide` must be placed after `--after-show`. */
.rrui__tooltip--right.rrui__tooltip--before-hide
{
	transform: translateX(var(--rrui-tooltip-hidden-distance));
}

.rrui__tooltip--after-show,
.rrui__tooltip--before-hide
{
	transition : opacity var(--rrui-tooltip-animation-duration), transform var(--rrui-tooltip-animation-duration);
}

.rrui__tooltip__target
{
	/* Prevents entering text selection mode
	   on mobile devices when tapping down and holding */
	user-select: none;
	/* Resets `cursor: pointer` set by `interactive={true}`'s `.rrui__button-reset`. */
	cursor: inherit;
	/* Resets `white-space : nowrap` set by `interactive={true}`'s `.rrui__button-reset`. */
	white-space: initial;
}